import React, { useRef, useState, useEffect } from "react";
import "./videoPlayer.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
      videoRef.current
        .play()
        .catch((error) => {
          console.error("Video autoplay failed:", error);
        });
    }
  }, [isMuted]);

  const toggleMute = () => {
    setIsMuted((prevMuted) => {
      if (videoRef.current) {
        videoRef.current.muted = !prevMuted;
      }
      return !prevMuted;
    });
  };

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        className="video-fullscreen"
        loop
        autoPlay
        playsInline
        muted={isMuted} // Ensure the video is initially muted
        onClick={toggleMute} // Allow users to toggle sound by tapping the video
      >
        <source src={`${process.env.PUBLIC_URL}/videos/main.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button onClick={toggleMute} className="mute-button">
        <i className={isMuted ? "fas fa-volume-mute" : "fas fa-volume-up"}></i>
      </button>
    </div>
  );
};

export default VideoPlayer;
